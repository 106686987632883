import React from 'react';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import BlogClassicData from '../data/blog/BlogList.json';

import { firebase } from '../firebase';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import BrandTwo from '../elements/brand/BrandTwo';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import ContactOne from '../elements/contact/ContactOne';

import TeamOne from '../elements/team/TeamOne'


const InternationalConsulting = () => {

    const [argioData, setArgioData] = React.useState([])
    const [servicesData, setServicesData] = React.useState([])
    const [clientsData, setClientsData] = React.useState([])

    // START Conectamos a BD y almacenamos resultados.
    React.useEffect(() => {

        const obtenerDatos = async () => {
            try {

                const db = firebase.firestore()

                const doc = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').get()
                const doc2 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('servicios').get()
                const doc3 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').get()

                const arrayData = doc.data()
                const arrayServices = doc2.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                const arrayClients = doc3.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))

                setArgioData(arrayData)
                setServicesData(arrayServices)
                setClientsData(arrayClients)



                // console.log(arrayData ? arrayData.imgBanner : "")
                // console.log(doc.data().direccion)

            } catch (error) {
                console.log(error)
            }
        }

        obtenerDatos()

    }, [])
    // ENDS Conectamos a BD y almacenamos resultados.


    return (
        <>
            <SEO title="ARGIO ||  Autotransportes" />
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${argioData.imgBanner})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h3>Autotransportes</h3>
                                    <h1 className="title display-one">{argioData ? argioData.nombre_negocio : null}  </h1>
                                    <p className="description"> {argioData ? argioData.eslogan : null} </p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border btn-icon" to="#">Contactanos <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start About Area  */}
                <AboutOne argioData={argioData} />
                {/* End About Area  */}

                {/* Start Team Area  */}
                <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = ""
                                            title = "Nuestro equipo..."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                        </div>
                    </div>
                    {/* End Team Area  */}
                    

                {/* START CTPAT area  */}
                <div className="service-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title" style={{ textAlign: "center" }}>Estamos certificados en CTPAT.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content" style={{ display: "flex" }}>
                                    <img style={{ margin: "auto" }} src={"./images/brand/brand-06.png"} alt="Brand Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END CTPAT area  */}


                {/* START nuestra historia area */}
                <div className="rwt-timeline-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    title="Nuestra Historia."
                                    // subtitle="Timeline"
                                /> */}
                                <h2 style={{ textAlign: 'center' }}>Nuestra Historia</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="dark-line" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* END nuestra historia area */}


                {/* START Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    //subtitle="What we can do for you"
                                    title="Nuestros Servicios"
                                // description="Aqui encontraras el servicio que seguro te ayudara..."
                                />
                            </div>
                        </div>
                        <ServiceFive
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                            servicesData={servicesData}
                        />
                        <br />

                        <AdvanceTabOne imgCovertura={argioData ? argioData.imgCovertura : ''} />

                    </div>
                </div>
                {/* END Service Area  */}


                {/* START CLIENTrand Area  */}
                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Algunos de Nuestros Clientes...</h3>
                                    {/* <button onClick={() => console.log("ClientsData: ", clientsData)} >chek me</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo
                                    brandStyle="brand-style-2"
                                    clientsData={clientsData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* END CLIENT Area  */}


                {/* START contact area */}
                <div className="container">
                    <ContactOne argioData={argioData} />
                </div>
                {/* END contact area */}


                <FooterTwo argioData={argioData} />
                <Copyright />
            </main>
        </>
    )
}

export default InternationalConsulting
