import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Pages import Here 
import InternationalConsulting from "./pages/InternationalConsulting";
import Privacy from './pages/PrivacyPolicy'
import AboutUs from "./pages/AboutUs";
import Admin from './pages/Admin';
import Login from './pages/Login';
import Contact from './elements/contact/Contact';
import DocArgio from './pages/DocArgio'

// Elements import Here 
import PageScrollTop from './components/pageToTop/PageScrollTop'
import Error from "./pages/Error";
import HeaderTwo from './common/header/HeaderTwo';

// Import Css Here 
import './assets/scss/style.scss';

//Import Firebase
// import { firebase } from './firebase'

import { auth } from './firebase';

const App = () => {

    const [firebaseUser, setFirebaseUser] = React.useState(false)
    // const [argioData, setArgioData] = React.useState([])

    React.useEffect(() => {

        auth.onAuthStateChanged(user => {
            // console.log("USER: ", user)
            if (user) {
                setFirebaseUser(user)
            } else {
                setFirebaseUser(null)
            }
        })



    }, [])

    return firebaseUser !== false ? (
        <Router>
            <PageScrollTop>
                <HeaderTwo
                    btnStyle="btn-small btn-icon"
                    HeaderSTyle="header-transparent"
                    firebaseUser={firebaseUser}
                />
                <Switch>
                    <Route
                        path={
                            "/.well-known/acme-challenge/6l-WlG20vLP_3G6l3eBwnUoOoiYNtIr0sgGgyrfqpkD7lIGnuwevr9zyMr1mN2ce"
                        }
                        exact component={DocArgio}
                    />
                    <Route path={"/"} exact component={InternationalConsulting} />
                    <Route path={"/admin"} exact component={Admin} />
                    <Route path={"/privacy"} exact component={Privacy} />
                    <Route path={"/nosotros"} exact component={AboutUs} />
                    <Route path={"/error"} exact component={Error} />
                    <Route path={"/login"} exact component={Login} />
                    <Route path={"/contacto"} exact component={Contact} />
                </Switch>
            </PageScrollTop>
        </Router>

    ) : (
        <p>Loading...</p>
    )
}

export default App