import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import ServiceFive from '../elements/service/ServiceFive';

import { firebase } from '../firebase';
import FooterTwo from '../common/footer/FooterTwo';
import BrandTwo from '../elements/brand/BrandTwo';
import AboutTwo from '../elements/about/AboutTwo';
import HeaderTwo from '../common/header/HeaderTwo';


const AboutUs = () => {

    const [argioData, setArgioData] = React.useState([])
    const [clientsData, setClientsData] = React.useState([])

    React.useEffect(() => {

        const obtenerDatos = async () => {
            try {

                const db = firebase.firestore()

                const doc = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').get()
                const doc3 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').get()

                const arrayData = doc.data()
                const arrayClients = doc3.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))

                setArgioData(arrayData)
                setClientsData(arrayClients)


                console.log(argioData.direccion)
                console.log(doc.data().direccion)

            } catch (error) {
                console.log(error)
            }
        }

        obtenerDatos()

    }, [])


    return (
        <>
            <SEO title="Nosotros || ARGIO - Autotransportes" />
            <main className="page-wrapper">
                    {/* <HeaderTopNews />
                    <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" /> */}

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">ARGIO autotransportes</span></h3>
                                    </div>
                                    <h1 className="title display-one">Transporte confiable, <br /> siempre a tu servicio.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutTwo argioData={argioData} />




                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title" style={{ textAlign: "center" }}>Estamos certificados en CTPAT.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content" style={{ display: "flex" }}>
                                    <img style={{ margin: "auto" }} src={"./images/brand/brand-06.png"} alt="Brand Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}






                <Separator />





                <div className="rwt-timeline-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Timeline"
                                    title="Nuestra Historia."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="no-gradient" />
                            </div>
                        </div>
                    </div>
                </div>



                <Separator />





                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--80 rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Algunos de Nuestros Clientes...</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo
                                    brandStyle="brand-style-2"
                                    clientsData={clientsData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}




                <FooterTwo argioData={argioData} />


            </main>
        </>
    )
}

export default AboutUs;
