import React from 'react';


const BrandTwo = (props) => {


    return (
        <ul className={`brand-list ${props.brandStyle}`}>
            {

                props.clientsData.map((data, index) => (
                    <li key={index}>
                        <a href="#link"><img src={`${data.urlImg}`} alt="Brand" /></a>
                    </li>
                ))
            }
        </ul>
    )
}

export default BrandTwo;
