import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const AboutTwo = (props) => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">


                    <div className="col-lg-12 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">¿Quienes Somos?.</span></h4>
                                    <h2 className="title mt--10">Acerca de ARGIO.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p> {props.argioData ? props.argioData.quienes_somos : null} </p>

                                    <div className="row">

                                        <div className='col-lg-6'>
                                            <h4>Mision:</h4>

                                            <p>
                                                {props.argioData ? props.argioData.mision : null}
                                            </p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h4>Vision:</h4>

                                            <p>
                                                {props.argioData ? props.argioData.vision : null}
                                            </p>
                                        </div>

                                    </div>
                                </ScrollAnimation>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;
