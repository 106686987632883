import React from 'react';
import Typed from 'react-typed';

const AboutOne = (props) => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src= {props.argioData.imgNosotros} alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title"> Acerca de Nosotros <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Eficiencia.",
                                            "Seguridad.",
                                            "Tecnologia.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p> {props.argioData ? props.argioData.quienes_somos : null} </p>

                                <div className="row">

                                    <div className='col-lg-6'>
                                        <h4>Mision:</h4>

                                        <p>
                                            {props.argioData ? props.argioData.mision : null}
                                        </p>
                                    </div>
                                    <div className='col-lg-6'>
                                        <h4>Vision:</h4>

                                        <p>
                                            {props.argioData ? props.argioData.vision : null}
                                        </p>
                                    </div>

                                </div>

                                {/* <div className="read-more-btn mt--4">
                                    <BrandOne />
                                    <p>Estamos cetificados en CTPAT</p>
                                    <a className="btn-default" href="#"><span>Mas de nosotros</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne
