import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/galery-image-01.png',
        title: 'Servicio de Transporte',
        description: 'Te ayudamos a transportar tu producto.'
    },
    {
        image: '/images/service/galery-image-02.png',
        title: 'Cruce Trans-Fronterizo',
        description: 'Trabajmos tanto en USA y Mexico.'
    },
    {
        image: '/images/service/galery-image-03.png',
        title: 'Servicios Especiales',
        description: 'Viajes urgentes y de doble operador.'
    }
]

const ServiceFive = ({ textAlign, serviceStyle, servicesData }) => {

    const [services, setServices] = React.useState(servicesData)

    useEffect(() => {
        setServices(servicesData)
        // console.log("serviciosss: ", servicesData)
        // console.log("STATE services: ", services)
    })

    return (
        <div className="row row--15 service-wrapper">
            {
                services.map(item => (
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={item.id}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <div className={`service ${serviceStyle} ${textAlign}`}>
                                <div className="inner">
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="#service" dangerouslySetInnerHTML={{ __html: item.servicio }}></Link>
                                        </h4>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: item.descripcion }}></p>
                                    </div>
                                    <div className="image">
                                        <img src={`${item.urlImg}`} alt="card Images" />
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))

            }
        </div>
    )
}
export default ServiceFive;