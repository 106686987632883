import React from 'react';
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";
import HeaderTwo from './header/HeaderTwo';

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                {/* <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" /> */}
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
