import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"

import { v4 } from 'uuid'


const firebaseConfig = {
    apiKey: "AIzaSyC245QjIU3hrgXh4pCPSA2mUzsIUzYVt7o",
    authDomain: "argio-6aaeb.firebaseapp.com",
    databaseURL: "https://argio-6aaeb-default-rtdb.firebaseio.com",
    projectId: "argio-6aaeb",
    storageBucket: "argio-6aaeb.appspot.com",
    messagingSenderId: "7549508811",
    appId: "1:7549508811:web:d593a58d49c3403f3d8f4e"
  };

  // Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const storage = getStorage(app)

const auth = firebase.auth(app)
const db = firebase.firestore()
//const storage = firebase.storage

export { firebase, auth, db, storage }


// -- // FUNCTION upload file to firestorage // -- //
export async function uploadFile(file) {
  const storageRef = ref(storage, v4())
  await uploadBytes(storageRef, file)
  const url = await getDownloadURL(storageRef)
  return url
}
