import React, { useEffect } from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = ({argioData}) => {

    const [whatsapp, setWhatsapp] = React.useState(argioData ? argioData.whatsapp : '')
    const [direccion,setDireccion] = React.useState(argioData ? argioData.direccion : '')
    const [email, setEmail] = React.useState(argioData ? argioData.email_info : '')


    useEffect(() => {
        // console.log("PROPS Contact: ", argioData)
        setWhatsapp(argioData.whatsapp)
        setDireccion(argioData.direccion)
        setEmail(argioData.email_info)
    })

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Whatsapp</h4>
                                        <p><a href={`tel:${whatsapp}`}> {whatsapp} </a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href={`mailto:${email}`}> {email} </a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Ubicación</h4>
                                        <p> {direccion} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>
        </>
    )
}
export default ContactOne;