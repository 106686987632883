import { Button, message } from 'antd'
import React from 'react'

import { auth, db } from '../firebase'

import { withRouter } from 'react-router-dom'

const Login = (props) => {

    const [email, setEmail] = React.useState('')
    const [pass, setPass] = React.useState('')
    const [esRegistro] = React.useState(false)

    // -- // FUNCTION para validar datos de login
    const procesarDatos = e => {
        e.preventDefault()
        if (!email.trim()) {
            message.error('Ingresa un Email')
            return
        }
        if (!pass.trim()) {
            message.error("Ingresa el Password")
            return
        }
        if (pass.length < 6) {
            message.error("Password Debe Ser Mayor a 6 caracteres")
            return
        }
        if (esRegistro) {
            registrar()
        } else {
            login()
        }
    }

    // -- // FUNCTION login // -- //

    const login = React.useCallback(async () => {
        try {
            const res = await auth.signInWithEmailAndPassword(email, pass)
            // console.log(res.user)
            setEmail('')
            setPass('')
            props.history.push('/admin')
        } catch (error) {
            // console.log(error)
            if (error.code === "auth/invalid-email") {
                message.error("Formato de Email incorrecto")
            }
            if (error.code === "auth/user-not-found") {
                message.error("Usuario no ecnontrado")
            }
            if (error.code === "auth/wrong-password") {
                message.error("Password incorrecto (3 intentos restantes)")
            }
        }
    }, [email, pass])

    // -- // FUNCTION registrar nuevo usuario // -- //
    const registrar = React.useCallback(async () => {

        try {
            const res = await auth.createUserWithEmailAndPassword(email, pass)
            message.success("Usuario registrado")
            // console.log("Exito: ", res.user)
            await db.collection('clcUsers').doc(res.user.uid).set({
                email: res.user.email,
                uid: res.user.uid,
            })
            await db.collection(res.user.uid).add({
                name: 'Tarea Ejemplo',
                fecha: Date.now()
            })
            setEmail('')
            setPass('')
            props.history.push('/admin')

        } catch (error) {
            // console.log(error)
            if (error.code === "auth/invalid-email") {
                message.error("Formato de Email incorrecto")
            } else if (error.code === "auth/email-already-in-use") {
                message.error("Este email ya esta registrado")
            }
        }
    }, [email, pass])


    return (
        <>
            <main className="page-wrapper">
                <div className="rwt-timeline-area rn-section-gapTop">
                    <div className="container" style={{ width: "80%", marginTop: '10%' }}>

                        <div className="row justify-content-center" >
                            <div className="col-10 col-lg-6" >
                                <h2 className='text-center mb-5'>
                                    {
                                        esRegistro ? "Registro de usuarios" : "Login de Accesos"
                                    }
                                </h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-8 col-md-6 col-xl-4">
                                <form onSubmit={procesarDatos} >

                                    <input
                                        type="email"
                                        className='"form-control mb-2'
                                        placeholder='Ingresa tu email'
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    <input
                                        type="password"
                                        className='"form-control mb-2'
                                        placeholder='Ingresa tu password'
                                        onChange={e => setPass(e.target.value)}
                                        value={pass}
                                    />
                                    <Button
                                        type='primary'
                                        style={{ marginLeft: "auto" }}
                                        onClick={procesarDatos}
                                    >
                                        {
                                            esRegistro ? 'Registrarse' : 'Acceder'
                                        }
                                    </Button>
                                    {/* <Button
                                        type='link'
                                        onClick={() => setEsRegistro(!esRegistro)}
                                    >
                                        {
                                            esRegistro ? '¿Ya estas registrado?' : "¿No tienes cuenta?"
                                        }
                                    </Button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    )
}

export default withRouter(Login)