import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { auth } from '../../firebase'
import { withRouter } from 'react-router-dom';

import { Tooltip } from 'antd';

import { LogoutOutlined } from '@ant-design/icons';

const Nav = (props) => {

    const cerraSesion = () => {
        auth.signOut()
            .then(() => {
                props.history.push('/login')
            })
    }
    return (
        <ul className="mainmenu">
            <li><NavLink to="/">Inicio</NavLink></li>
            <li><NavLink to="/nosotros">Nosotros</NavLink></li>
            <li><NavLink to="/contacto">Contacto</NavLink></li>

            {/* <li><Link to="/nosotros">Nosotros</Link></li>
            <li><Link to="/contact">Contacto</Link></li> */}
            {
                props.firebaseUser !== null ? (
                    <li><NavLink to="/admin" >Admin</NavLink></li>
                ) : null
            }
            {
                props.firebaseUser !== null ? (
                    <li>
                        <Tooltip title="Cerrar Sesion" >
                            <Link
                                className=" btn-medium btn-border btn-icon"
                                onClick={() => cerraSesion()}
                                to=""

                            >
                                <i className="icon"><LogoutOutlined /></i>
                            </Link>
                        </Tooltip>


                        {/* <button
                            onClick={() => cerraSesion()}
                        >
                            Cerrar Sesion
                        </button> */}

                    </li >
                ) : (
                    <li> <NavLink to="login">Login</NavLink> </li >
                )
            }
        </ul >
    )
}
export default withRouter(Nav);
