import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";

import { firebase } from '../../firebase'

const Contact = () => {

    const [argioData, setArgioData] = React.useState([])

    React.useEffect(() => {

        const obtenerDatos = async () => {
            try {

                const db = firebase.firestore()

                const doc = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').get()
                // const doc2 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('servicios').get()
                // const doc3 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').get()

                const arrayData = doc.data()
                // const arrayServices = doc2.docs
                //     .map(doc => ({ id: doc.id, ...doc.data() }))
                // const arrayClients = doc3.docs
                //     .map(doc => ({ id: doc.id, ...doc.data() }))

                setArgioData(arrayData)
                // setServicesData(arrayServices)
                // setClientsData(arrayClients)

                // console.log(arrayData ? arrayData.imgBanner : "")
                // console.log(doc.data().direccion)

            } catch (error) {
                console.log(error)
            }
        }

        obtenerDatos()

    }, [])

    return (
        <>
            <SEO title="Contact || ARGIO - Autotransportes" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                /> */}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle="Formulario de Contacto"
                                        title="Queremos Conocerte."
                                        description=""
                                    />
                                </div>
                            </div>
                            <ContactOne argioData={argioData} />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;