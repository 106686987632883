import { Button, Popconfirm, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'
import Copyright from '../common/footer/Copyright'
// importamos FIREBASE
import { firebase, uploadFile } from '../firebase'
import Upload from '../elements/upload/Upload'

import { auth } from '../firebase'
import { withRouter } from 'react-router-dom'



const Admin = (props) => {

  //inicializamos el state de los datos de ARGIO
  const [argioData, setArgioData] = React.useState()

  //Asiganmos los valores a los STATES
  const [nombreNegocio, setNombreNegocio] = React.useState()
  const [eslogan, setEslogan] = React.useState()
  const [quienesSomos, setQuienessomo] = React.useState()
  const [vision, setVision] = React.useState()
  const [mision, setMision] = React.useState()
  const [whatsapp, setWhatsapp] = React.useState()
  const [email, setEmail] = React.useState()
  const [direccion, setDireccion] = React.useState()
  const [facebook, setFacebook] = React.useState()
  const [instagram, setInstagram] = React.useState()
  const [website, setWebsite] = React.useState()
  const [servicios, setServicios] = React.useState([])
  const [servicio, setServicio] = React.useState("")
  const [descServ, setDescServ] = React.useState("")
  const [modoEdicion, setModoEdicion] = React.useState(true)
  const [clientes, setClientes] = React.useState([])
  const [cliente, setCliente] = React.useState("")

  const [file, setFile] = React.useState(null)

  const [loading, setLoading] = React.useState(false)
  const [imgURL, setImgURL] = React.useState('')
  const [uploadValue, setUploadValue] = React.useState(1)

  const [imgBanner, setImgBanner] = React.useState('')

  const [user, setUser] = React.useState(null)


  /// --- /// READ Obtemos la data y la informacion del usuario /// --- ///
  React.useEffect(() => {

    // -- // FUNCTION para obtener los datos de la base de datos
    const obtenerDatos = async () => {
      try {
        //Inicializamos Firebase
        const db = firebase.firestore()

        // Obtenemos la informacion general de la BD, Servicios y Cientes
        const doc = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').get()
        const doc2 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('servicios').get()
        const doc3 = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').get()

        // Asignamos los array a las constantes
        const arrayData = doc.data()
        const servicesArray = doc2.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
        const clientsArray = doc3.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))


        // Asignamos los valores a cada STATE
        setArgioData(arrayData)
        setNombreNegocio(arrayData.nombre_negocio)
        setEslogan(arrayData.eslogan)
        setQuienessomo(arrayData.quienes_somos)
        setVision(arrayData.vision)
        setMision(arrayData.mision)
        setWhatsapp(arrayData.whatsapp)
        setEmail(arrayData.email_info)
        setDireccion(arrayData.direccion)
        setFacebook(arrayData.facebook)
        setInstagram(arrayData.instagram)
        setWebsite(arrayData.website)
        setServicios(servicesArray)
        setClientes(clientsArray)
        setImgBanner(arrayData.imgBanner)


        // console.log("ARGIO DB:", arrayData)
        // console.log("Servicios: ", servicios)
        // console.log("banner: ", imgBanner)
      }
      catch (error) {
        console.log(error)
      }
    }

    if (auth.currentUser) {
      // console.log("Existe usuario")
      message.success("Beinvenido Admin")
      setUser(auth.currentUser)
      // console.log("user data: ", auth.currentUser)
      obtenerDatos()

    } else {
      console.log("NO existe en usuario")
      props.history.push('/login')
    }

  }, [])


  /// --- ///DELETE servicios de DB /// --- ///
  const deleteService = async (id) => {

    var answer = window.confirm("¿Eliminar Servicio?");
    if (answer) {
      //DELETE function
      try {

        const db = firebase.firestore() //Conectamos a la BD
        await db.collection('clcDataArgio') // Eliminamos el servicio
          .doc('4Rp3Z1gbgwJWjWLGbqKJ')
          .collection('servicios')
          .doc(id)
          .delete()
        const servFilter = servicios.filter(item => item.id !== id) //Filtramos el state
        setServicios(servFilter) //Seteamos el filtro al state de servicios
        message.success("Servicio Eliminado") //Mostramos mensaje de HECHO 

      } catch (error) {
        console.log(error)
      }
    }
    else {
      //some code
    }
  }

  /// --- /// DELETE client function /// --- ///
  const deleteClient = async (id) => {

    var answer = window.confirm("¿Eliminar ciente?")
    if (answer) {
      //DELETE function
      try {

        const db = firebase.firestore()
        await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').doc(id).delete()
        const clientFilter = clientes.filter(item => item.id !== id)
        setClientes(clientFilter)
        message.success('Cliente Eliminado')

      } catch (error) {
        console.log(error)
      }
    }
  }

  ///---/// CREATE service function ///---///
  const createService = async (e) => {
    e.preventDefault()

    // Verificamos que los inputs contengan informacion
    if (!servicio.trim()) {
      message.error('Servicio vacio')
      return
    } else if (!descServ.trim()) {
      message.error('Descripcion vacia')
      return
    } else if (file === null) {
      message.info('Selecciona una imagen')
      return
    }


    // Realizamos un TRY para confirmar la escitura en BD
    try {
      const db = firebase.firestore()
      const result = await uploadFile(file)
      //console.log(result)
      const nuevoServicio = {
        servicio: servicio,
        descripcion: descServ,
        urlImg: result
      }

      const data = await db.collection("clcDataArgio").doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('servicios').add(nuevoServicio)

      setServicios([
        ...servicios,
        { ...nuevoServicio, id: data.id }
      ])

      message.success("Servicio Agregado")
      setServicio('')
      setDescServ('')
      setFile(null)

    } catch (error) {
      console.log(error)
      message.error('Fallo interno, Intente mas tarde.', error)
    }


  }

  /// --- /// CREATE client function /// --- ///
  const createClient = async (e) => {
    e.preventDefault()

    //Verificamos que los inputs tengan info
    if (!cliente.trim()) {
      message.error('Cliente vacio')
      return
    } else if (file === null) {
      message.error('Selecciona una imagen')
      return
    }

    // Realizamos un TRY para confrimar la escritura de BD
    try {
      const db = firebase.firestore()
      const result = await uploadFile(file)
      //console.log(result)
      const nuevoCliente = {
        cliente: cliente,
        urlImg: result
      }

      const data = await db.collection('clcDataArgio').doc('4Rp3Z1gbgwJWjWLGbqKJ').collection('clientes').add(nuevoCliente)

      setClientes([
        ...clientes,
        { ...nuevoCliente, id: data.id }
      ])

      message.success('Cliente agregado')
      setCliente('')
      setFile(null)

    } catch (error) {
      console.log(error)
      message.error('Fallo interno. Intente mas tarde.')
    }

  }

  ///---/// UPDATE Data Argio grl function ///---///
  const updateDataArgio = async (e) => {
    e.preventDefault()
    // if (!nombreNegocio.trim()) {
    //   message.error('Todos los campos deben estar completos.')
    //   return
    // } else if (!eslogan.trim()) {
    //   message.error('Falta el campo `slogan`')
    //   return
    // }

    try {

      const db = firebase.firestore()
      await db.collection("clcDataArgio").doc('4Rp3Z1gbgwJWjWLGbqKJ').update({
        nombre_negocio: nombreNegocio,
        eslogan: eslogan,
        quienes_somos: quienesSomos,
        vision: vision,
        mision: mision,
        whatsapp: whatsapp,
        email_info: email,
        direccion: direccion,
        facebook: facebook,
        instagram: instagram,
        website: website
      })
      message.success("Datos Actualizados")
      setModoEdicion(!modoEdicion)
    } catch (error) {
      console.log(error)
    }
  }

  /// --- /// Modo edicion encendico/apagado /// --- ///
  const editMode = () => {
    setModoEdicion(!modoEdicion)
  }

  /// --- /// FUNCTION beforeUpload /// --- ///
  function beforeUpload(file) {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt5m = (file.size / 1024 / 1024) < 5;

    if (!isJpgOrPng) {
      // console.log("tipo imagen", file.type)
      return message.error('Solo puedes subir archivos JPG o PNG');
    }

    else if (isLt5m) {

      // console.log("Tamaño", file.size)
      // console.log("isLt5m", (file.size / 1024 / 1024) < 5)

      const storageRef = firebase.storage().ref(file.name);
      const task = storageRef.put(file);

      task.on('state_changed', (snapshot) => {
        let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setUploadValue(percentage);
      }, (error) => {
        console.log("Error al guardar", error.message)
      }, () => {
        // UPLOAD complete
        setImgURL(task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          setImgURL(downloadURL)
        }))
        setLoading(false)
      })
      message.success("Pasooo")
    }

    else {
      //message.success("FALLO")
      message.error('La imagen es mas grande de 5MB')
      setLoading(false)
    }
  }

  /// --- /// HANDLE cambios /// --- ///
  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
  }


  // -- // BUTTON to upload images // -- //
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }} > Upload </div>
    </div>
  )


  return (
    <>
      <main className='page-wrapper'>

        <div className="rwt-timeline-area rn-section-gapTop">
          <div className="container" style={{ width: "80%" }}>

            <div className="row mt--6 row--15" style={{ marginTop: 50 }}>

              <div className="row" >

                <div className="col-6" >
                  {
                    user && (
                      <h3>{user.email}</h3>
                    )
                  }
                  {nombreNegocio}
                  Admin
                </div>

                <div className="col-6" style={{ display: 'flex' }}>

                  <Button hidden={!modoEdicion} onClick={editMode} type='primary' alert="true" style={{ marginLeft: "auto" }} >
                    HABILITAR EDICION
                  </Button>

                </div>

              </div>


              <h4 className='mb-5' style={{ backgroundColor: "gray", borderRadius: "10px" }}>Agrega, elimina o edita informacion de tu website 👇🏽</h4>


              {/* * * FORMS para manejar la BD * * */}
              <div className="">

                {/* * * FORM datos generales * * */}
                <form action="" style={{ marginBottom: "50px" }}>

                  {/* Info del negocio */}
                  <div className="row mb-3" style={{ marginTop: "15px" }}>

                    <div className="col-lg-4">
                      <h5>Nombre del negocio</h5>
                      <input disabled={modoEdicion} type="text" defaultValue={nombreNegocio} onChange={e => setNombreNegocio(e.target.value)} />
                    </div>

                    <div className="col-lg-8">
                      <h5>Slogan de inicio</h5 >
                      <textarea disabled={modoEdicion} type="text" defaultValue={eslogan} onChange={e => setEslogan(e.target.value)} />
                    </div>

                  </div>

                  <div className="row mb-3" style={{ marginTop: "15px" }}>

                    <div className="col lg-12">
                      <h5>Quienes somos?</h5>
                      <textarea disabled={modoEdicion} type="time" defaultValue={quienesSomos} onChange={e => setQuienessomo(e.target.value)} />
                    </div>

                  </div>

                  <div className="row mb-3" style={{ marginTop: "15px" }}>
                    <div className="col-lg-6">
                      <h5>Vision:</h5>
                      <textarea disabled={modoEdicion} type="text" defaultValue={vision} onChange={e => setVision(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                      <h5>Mision:</h5>
                      <textarea disabled={modoEdicion} type="text" defaultValue={mision} onChange={e => setMision(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3" style={{ marginTop: "15px" }}>

                    <div className="col lg-4">
                      <h5>Whatsapp:</h5>
                      <input disabled={modoEdicion} type="tel" defaultValue={whatsapp} onChange={e => setWhatsapp(e.target.value)} />
                    </div>

                    <div className="col-lg-4">
                      <h5>E-mail:</h5>
                      <input disabled={modoEdicion} type="email" defaultValue={email} onChange={e => setEmail(e.target.value)} />
                    </div>

                    <div className="col-lg-4">
                      <h5>Direccion:</h5>
                      <input disabled={modoEdicion} type="text" defaultValue={direccion} onChange={e => setDireccion(e.target.value)} />
                    </div>
                  </div>

                  {/* Redes sociales */}
                  <div className="row mb-3" style={{ marginTop: "15px", }}>
                    <div className="col-lg-4">
                      <h5>Facebook:</h5>
                      <input disabled={modoEdicion} type="text" defaultValue={facebook} onChange={e => setFacebook(e.target.value)} />
                    </div>
                    <div className="col-lg-4">
                      <h5>Instagram:</h5>
                      <input disabled={modoEdicion} type="text" defaultValue={instagram} onChange={e => setInstagram(e.target.value)} />
                    </div>
                    <div className="col-lg-4">
                      <h5>Website:</h5>
                      <input disabled={modoEdicion} type="text" defaultValue={website} onChange={e => setWebsite(e.target.value)} />
                    </div>
                  </div>

                  {/* SAVE button */}
                  <div className="row" style={{ marginTop: "50px" }}>
                    <div className="col-lg-6" style={{ display: "flex" }}>
                      <Button className='btn btn-danger' hidden={modoEdicion} onClick={editMode} type='primary' style={{ marginLeft: "auto" }} >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6 " style={{ display: 'flex' }} >
                      <Button className='btn btn-success' hidden={modoEdicion} onClick={updateDataArgio} type='primary' alert="true" style={{ marginLeft: "auto" }} >
                        Actualizar
                      </Button>
                    </div>
                  </div>

                </form>

                {/* * * FORM servicios * * */}
                <form action="">
                  <div className="row col-lg-12" style={{ marginTop: "30px" }}>
                    < h5>Servicios (imagenes de 1600 x 992):</h5>

                    <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                      <div className="row col-12">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3">

                          <input
                            type="text"
                            name='nombreServicio'
                            placeholder='Servicio'
                            onChange={e => setServicio(e.target.value)}
                            value={servicio}
                            required
                          />

                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                          <input
                            type="text"
                            name='descServicio'
                            placeholder='Descripcion'
                            onChange={e => setDescServ(e.target.value)}
                            value={descServ}
                            required
                          />
                        </div>

                        <div display={{ display: 'flex' }} className="col-lg-2 col-md-2 col-sm-2">
                          <label style={{ marginLeft: 'auto' }} htmlFor="fileBrow">Cargar...</label>
                          <input
                            style={{ display: 'none' }}
                            onChange={e => setFile(e.target.files[0])}
                            type="file"
                            name=""
                            id="fileBrow"
                            accept='image/png, image/jpeg'
                          />
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <Button
                            className="btn btn-success"
                            type='primary'
                            shape='circle'
                            onClick={createService}
                          >
                            +
                          </Button>

                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-4">
                      {
                        servicios.map(item => (
                          <div className="row">

                            <li style={{ display: 'flex' }} key={item.id}   >

                              - {item.servicio}

                              <Button
                                className='btn btn-danger btn-sm'
                                style={{ marginLeft: "auto" }}
                                onClick={() => deleteService(item.id)}
                                exact="true"
                              >
                                X
                              </Button>
                            </li>

                          </div>
                        ))
                      }
                    </div>

                  </div>

                </form>

                {/* * * FORM Clientes * * */}
                <form action="">
                  <div className="row col-lg-12">
                    <h5>Clientes (imagenes de 232 x 110):</h5>

                    <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                      <div className="row col-12">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                          <input
                            type="text"
                            name='nombreCliente'
                            placeholder='Cliente'
                            required
                            onChange={e => setCliente(e.target.value)}
                            value={cliente}
                          />
                        </div>

                        <div display={{ display: 'felx' }} className="col-lg-2 col-md-2 col-sm-2">
                          <label style={{ marginLeft: 'auto' }} htmlFor="fileBrow">Cargar...</label>
                          <input
                            style={{ display: 'none' }}
                            onChange={e => setFile(e.target.files[0])}
                            type="file"
                            name=""
                            id="fileBrow"
                            accept='image/png, image/jpeg'
                          />
                        </div>

                        <div className="col-2">
                          <Button
                            className='btn btn-success'
                            type='primary'
                            shape='circle'
                            onClick={createClient}
                          >
                            +
                          </Button>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-4 col-4">

                      {
                        clientes.map(item => (
                          <div className="row">
                            <li style={{ display: 'flex' }} key={item.id} >
                              - {item.cliente}
                              <Button
                                className='btn btn-danger btn-sm'
                                style={{ marginLeft: "auto" }}
                                onClick={() => deleteClient(item.id)}
                              >
                                x
                              </Button>

                            </li>
                          </div>
                        ))

                      }

                    </div>

                  </div>
                </form>

                {/* * * FORM imgs del sitio * * */}
                <form action="" style={{ marginTop: "30px" }}>

                  <div className="row mt-3" style={{ marginBottom: "30px", marginTop: "30px" }}>

                    <h5>Imagenes de sitio</h5>

                    <div className="col-lg-12">

                      <div className="row" style={{ display: 'flex' }}>

                        <div className="col-lg-4" style={{ marginLeft: 'auto' }}>

                          <h6>Banner principal (1800 x 900)</h6>
                          <Upload imgUpload={argioData ? argioData.imgBanner : ''} type={'banner'} />

                        </div>

                        <div className="col-lg-4">

                          <h6>Imagen Nosotros  (543 x 642 )</h6>
                          <Upload
                            imgUpload={argioData ? argioData.imgNosotros : ''}
                            type={'nosotros'}
                          />
                        </div>

                        <div className="col-lg-4">

                          <h6>Mapa Servicio (1000 x 720)</h6>
                          <Upload
                            imgUpload={argioData ? argioData.imgCovertura : ''}
                            type={'covertura'}
                          />

                        </div>

                      </div>

                    </div>


                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
        {/* <FooterTwo argioData={argioData} /> */}
        <Copyright />
      </main>
    </>
  )
}

export default withRouter(Admin)