import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import {message} from 'antd'

const Result = () => {
    return (
        message.success("Tu mensaje fue enviado. Te contactaremos pronto.")
        // <p className="success-message">Tu mensaje fue enviado. Te contactaremos pronto.</p>
    )
}

function ContactForm({ props, formStyle }) {
    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'argiomx_656',
                'template_kv46zki',
                e.target,
                'zAv2rB0d8dg19Ima6'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="from_name"
                    id="from_name"
                    placeholder="Tu Nombre"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="reply_to"
                    id="reply_to"
                    placeholder="Tu Email"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Tu Telefono"
                    required
                />
            </div>


            <div className="form-group">
                <input
                    type="text"
                    name="subject"
                    placeholder="Asunto"
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Tu Mensaje..."
                    required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Enviar Ahora</button>
            </div>

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
